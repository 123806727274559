import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { SettingsType } from '@/types';
import { local } from '@/pages/api/api';

export type SettingsProviderProps = PropsWithChildren & {};

export type SettingsProviderContext = {
  creditCardDiscount: number;
  pixDiscount: number;
  setCreditCardDiscount: (discount: number) => void;
  setPixDiscount: (discount: number) => void;
  isReady: boolean;
}

const Context = createContext<SettingsProviderContext>({} as SettingsProviderContext);

export function SettingsProvider({ children }: SettingsProviderProps) {
  const [creditCardDiscount, setCreditCardDiscount] = useState<number>(0);
  const [pixDiscount, setPixDiscount] = useState<number>(0);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        const {
          data: { credit_card_discount, pix_discount },
        } = await local('/api/settings');
        setCreditCardDiscount(credit_card_discount);
        setPixDiscount(pix_discount);
        setIsReady(true)
      } catch (err) {
        console.log('settings fetch err', `${err}`);
      }
    }

    fetch();
  }, []);

  return (
    <Context.Provider value={{ creditCardDiscount, pixDiscount, setCreditCardDiscount, setPixDiscount, isReady }}>
      {children}
    </Context.Provider>
  );
}

export function useSettings() {
  return useContext(Context);
}
