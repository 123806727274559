import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { MenuType } from '@/types';

export type LayoutType = {
  seo: {
    title: string;
    meta_tags: any[];
  };
  breadcrumbs: BreadCrumbType[];
  menus: MenuType[];
};

export type BreadCrumbType = {
  label: string;
  href: string;
  active: boolean;
};

export type LayoutProviderContext = {
  layout: LayoutType;
  setLayout: Dispatch<SetStateAction<LayoutType>>;
  setData: (d: LayoutType) => void;
  breadcrumbs: any;
  setMenu: (v: MenuType[]) => void;
};

export type LayoutProviderProps = PropsWithChildren & {};

const defaults: LayoutType = {
  seo: {
    title: 'Rent a Car - Alugue Foco',
    meta_tags: [],
  },
  breadcrumbs: [],
  menus: [],
};

const Context = createContext<LayoutProviderContext>(
  {} as LayoutProviderContext,
);

export function LayoutProvider({ children }: LayoutProviderProps) {
  const [layout, setLayout] = useState<LayoutType>(defaults);

  const setData = (data: Partial<LayoutType>) => {
    setLayout({
      ...layout,
      ...data,
    });
  };

  const setMenu = (menus: MenuType[]) => {
    setLayout({
      ...layout,
      menus,
    });
  };

  const breadcrumbs = {
    push(data: BreadCrumbType) {
      setData({
        breadcrumbs: [...layout.breadcrumbs, data],
      });
    },
    reset() {
      setData({
        breadcrumbs: defaults.breadcrumbs,
      });
    },
    set(data: BreadCrumbType[]) {
      setData({
        breadcrumbs: data,
      });
    },
  };

  return (
    <Context.Provider
      value={{ layout, setLayout, setData, breadcrumbs, setMenu }}
    >
      {children}
    </Context.Provider>
  );
}

export function useLayout() {
  return useContext(Context);
}
