import { useRouter } from 'next/router';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { EstimateOptionalType, toArray } from '@/helpers/optionals';
import useOptionals from '@/hooks/useOptionals';
import { api, local } from '@/pages/api/api';
import { EstimateType, VehicleType, DepotType } from '@/types';
import { useSettings } from './settings';
import { getCdpIdFromQuery } from '@/helpers/query';

type ItineraryType = {
  pickup_store: string;
  return_store: string;
  pickup_date: string;
  return_date: string;
  pickup_time: string;
  return_time: string;
};

type RateType = {
  rate_id: string;
  vehicle_rate: string;
  vehicle_class: string;
};

interface ReservationContextInterface {
  itinerary?: ItineraryType;
  rate?: any;
  optionals?: EstimateOptionalType[];
  renter?: any;
  estimate?: EstimateType;
  setEstimate: any;
  depot?: DepotType;
  returnDepot?: DepotType;
  vehicle?: VehicleType;
  isPrePaid: boolean;
  paymentMethod: 'pix' | 'credit_card' | 'pos_paid';
  reservation: any;
  setReservation: any;
  setPaymentMethod: Function;
  user: any;
  setUser: any;
  reset: () => void;
  cpdid: string;
}

const Context = createContext<ReservationContextInterface>(
  {} as ReservationContextInterface,
);

export function ReservationProvider({ children }: PropsWithChildren) {
  const { query } = useRouter();
  const { pixDiscount, creditCardDiscount, isReady } = useSettings();

  const fromQuery = useCallback(
    (fields: string[]) => {
      return fields.reduce((obj, key) => ({ ...obj, [key]: query[key] }), {});
    },
    [query],
  );

  const itinerary = useMemo<ItineraryType | undefined>(() => {
    if (query) {
      return fromQuery([
        'pickup_store',
        'return_store',
        'pickup_date',
        'return_date',
        'pickup_time',
        'return_time',
      ]) as ItineraryType;
    }
  }, [query]);

  const rate = useMemo<RateType | undefined>(() => {
    if (query) {
      return fromQuery(['rate_id', 'vehicle_rate', 'vehicle_class']) as RateType;
    }
  }, [query]);

  const cpdid = useMemo<string>(() => {
    if (query.cdp_id) {
      return query.cdp_id as string;
    }

    return 'WEBXML';
  }, [query])

  const { opts } = useOptionals({ enabled: !!query.extras });

  const [renter, setRenter] = useState();
  const [optionals, setOptionals] = useState<EstimateOptionalType[]>();
  const [estimate, setEstimate] = useState<EstimateType | undefined>();
  const [depot, setDepot] = useState<DepotType | undefined>();
  const [returnDepot, setReturnDepot] = useState<DepotType | undefined>();
  const [vehicle, setVehicle] = useState<VehicleType | undefined>();
  const [paymentMethod, setPaymentMethod] = useState<
    'pix' | 'credit_card' | 'pos_paid'
  >('credit_card');
  const [reservation, setReservation] = useState({});
  const [user, setUser] = useState({});

  const isPrePaid = useMemo(
    () => paymentMethod === 'pix' || paymentMethod === 'credit_card',
    [paymentMethod],
  );

  useEffect(() => {
    if (query.extras && opts) {
      const aux = (fromQuery(['extras']) as any).extras;
      const extras = toArray(Array.isArray(aux) ? aux : [aux]);
      extras.forEach(e => {
        e.optional = opts.find(o => o.coral_code === e.code);
      });
      setOptionals(extras);
    } else if (typeof optionals !== 'undefined') {
      setOptionals(undefined);
    }
  }, [query.extras, opts, fromQuery]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: depots } = await api(
        `/depots?coral_code=${query.pickup_store}`,
      );
      setDepot(depots[0]);
    };

    if (query.pickup_store) fetchData();
  }, [query.pickup_store]);

  useEffect(() => {
    const fetchReturnDepot = async () => {
      if (query.return_store) {
        const { data: depots } = await api(
          `/depots?coral_code=${query.return_store}`,
        );
        setReturnDepot(depots[0]);
      }
    };

    if (query.pickup_store === query.return_store) {
      setReturnDepot(depot);
    } else {
      fetchReturnDepot();
    }
  }, [query.return_store, query.pickup_store, depot]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: vehicles } = await api(
        `/vehicles?${new URLSearchParams({ group: query.vehicle_class as any })}`,
      );

      setVehicle(vehicles[0]);
    };

    fetchData();
  }, [query.vehicle_class]);

  useEffect(() => {
    const fetchData = async () => {
      let payload: any = {};

      payload = {
        ...payload,
        pickup_datetime: `${query.pickup_date}T${query.pickup_time}:00`,
        return_datetime: `${query.return_date}T${query.return_time}:00`,
        pickup_store: query.pickup_store,
        return_store: query.return_store,
        promo_code: query.coupon,
        cdp_id: getCdpIdFromQuery(query),
      };

      if (paymentMethod === 'pix') {
        payload = {
          ...payload,
          discount: pixDiscount
        }
      } else if (paymentMethod === 'credit_card') {
        payload = {
          ...payload,
          discount: creditCardDiscount
        }
      } else {
        payload = {
          ...payload,
          discount: 0
        }
      }

      if (query.rate_id) {
        payload = {
          ...payload,
          rate_id: query.vehicle_rate,
          class_code: query.vehicle_class,
        };
      }

      if (query.extras) {
        payload.optionals = toArray(
          Array.isArray(query.extras) ? query.extras : [query.extras],
        );
      }

      if (payload.class_code) {
        const { data } = await local.post('/api/estimate', payload);
        setEstimate(data);
      }
    };

    if (isReady) {
      fetchData();
    }
  }, [
    query.extras,
    query.pickup_date,
    query.pickup_store,
    query.pickup_time,
    query.rate_id,
    query.return_date,
    query.return_store,
    query.return_time,
    query.vehicle_class,
    query.vehicle_rate,
    paymentMethod,
    isReady
  ]);

  const reset = () => {
    setRenter(undefined);
    setOptionals(undefined);
    setEstimate(undefined);
    setDepot(undefined);
    setReturnDepot(undefined);
    setVehicle(undefined);
    setPaymentMethod('credit_card');
    setReservation({});
    setUser({});
  }

  return (
    <Context.Provider
      value={{
        depot,
        returnDepot,
        vehicle,
        itinerary,
        rate,
        optionals,
        renter,
        estimate,
        setEstimate,
        isPrePaid,
        paymentMethod,
        setPaymentMethod,
        reservation,
        setReservation,
        user,
        setUser,
        reset,
        cpdid
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useReservation() {
  return useContext(Context);
}
