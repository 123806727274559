import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import Circle from 'progressbar.js/circle';
import { useRouter } from 'next/router';
import { useReservation } from './reservation';

export type UpdateBarProps = {
  title: string;
  step: number;
  progress: number;
};

export type ReservationBarType = {
  title: string;
  step: number;
  progress: number;
  values: (string | null)[];
};

export interface ReservationBarInterface {
  reservationBar: ReservationBarType;
  setReservationBar: Function;
  progressBar?: Circle;
  setProgressBar: Dispatch<SetStateAction<Circle | undefined>>;
  setValue: (n: number, s: string) => void;
  updateBar: (props: UpdateBarProps) => void;
  setText: (t: string) => void;
  reset: () => void;
}

const Context = createContext<ReservationBarInterface>(
  {} as ReservationBarInterface,
);

export function ReservationBarProvider({ children }: PropsWithChildren) {
  const router = useRouter();
  const { reset: reservationReset } = useReservation();

  const [progressBar, setProgressBar] = useState<Circle>();
  const [reservationBar, setReservationBar] = useState<ReservationBarType>({
    title: 'Itinerário',
    step: 1,
    progress: 0,
    values: [null, null, null, null],
  });

  const reset = () => {
    setReservationBar({
      title: 'Itinerário',
      step: 1,
      progress: 0,
      values: [null, null, null, null],
    });

    reservationReset();
  }

  const setText = (text: string) => {
    setReservationBar({ ...reservationBar, title: text });
  };

  const setValue: ReservationBarInterface['setValue'] = (step, value) => {
    const newValues = [...reservationBar.values];

    newValues[step - 1] = value;

    setReservationBar({
      ...reservationBar,
      values: newValues,
    });
  };

  const updateBar = useCallback((bar: UpdateBarProps) => {
    setReservationBar(prev => ({ ...prev, ...bar }));
  }, []);

  return (
    <Context.Provider
      value={{
        reservationBar,
        setReservationBar,
        progressBar,
        setProgressBar,
        setValue,
        updateBar,
        setText,
        reset
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useReservationBar() {
  return useContext(Context);
}
