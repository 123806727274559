import { parse, format } from 'date-fns';

export const getDataFromQuery = (query: any, key: string, initial: any): any => {
  if (typeof query[key] === 'string') {
    const value = decodeURIComponent(query[key] as string);

    if (key === 'pickup_date' || key === 'return_date') {
      const formats = ['dd/MM/yyyy', 'dd/MM/yy'];
      let parseFormat = 'yyyy-MM-dd';
      let formatDate = 'yyyy-MM-dd';

      if (formats.includes(query.date_format as string)) {
        parseFormat = query.date_format as string;
      }

      const parsed = parse(value, parseFormat, new Date());
      return parsed.toString() === 'Invalid Date' ? initial : format(parsed, formatDate);
    }

    return value;
  }

  return initial;
}

export const getCdpIdFromQuery = (query: any, path_name: string = 'cdp_id', initial: string = 'WEBXML'): string => {
  if (query[path_name]) {
    return query[path_name];
  }

  return initial;
}