import { useState, useEffect } from 'react';

import { AxiosError } from 'axios';

import { api } from '@/pages/api/api';
import { OptionalType } from '@/types';

type UseOptionalsProps = {
  enabled: boolean;
};

const useOptionals = ({ enabled }: UseOptionalsProps) => {
  const [opts, setOpts] = useState<OptionalType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const { data: opts } = await api.get('/optionals');

        setOpts(opts);
        setLoading(false);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setLoading(false);
      }
    };

    enabled && fetchData();
  }, [enabled]);

  return { opts, loading, error };
};

export default useOptionals;
